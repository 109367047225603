import React from "react";
import styled from "styled-components";

export const EmptyStaffMessageContainer = styled.div`
  padding: 1rem 0 1rem 0;
  width: 113ch;
  text-align: center;
  font-size: inherit;
  font-family: Courier New;
  margin-left: 12ch;
`

export const StaffRuler = styled.div.withConfig({
  shouldForwardProp: (prop) => !['staffHeight'].includes(prop),
})`
  border-right: 2px dotted rgba(0, 0, 0, 0.25);
  padding-right: 4px;
  position: absolute;
  left: 125ch;
  height: ${(props) => props.staffHeight !== undefined ? props.staffHeight  : "100vh"};
  min-height: calc(100% - 1rem);
  font-size: inherit;
  top: 5px;
  font-family: inherit;
  font-size: inherit;
  letter-spacing: inherit;
`

export const MeasureNumberContainer = styled.div`
  margin-top: 1rem;
  font-size: inherit;
  position: relative;
  left: 120ch;
  top: 0;
  width: fit-content;
`

export const StaffContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isDragging'].includes(prop),
})`
  background: ${(props) =>
    props.isDragging ? "rgba(255,255,255,0.75)" : "transparent"};
  box-shadow: ${(props) =>
    props.isDragging ? "2px 2px 3px rgba(0,0,0,0.25)" : "none"};
  border-radius: 8px;
  display: grid;
  grid-template-columns: 12ch 4px auto;
  transition: margin-top ease-in 0.5s;
  margin-top: 0rem;
  margin: .25rem 0px;
  min-width: 125ch;
  max-width: fit-content;
  font-family: "Courier New", Courier, monospace;
  font-size: inherit;
  letter-spacing: inherit;
`;

export const StaffContainerHeader = styled.div`
  border-radius: 8px;
  display: grid;
  grid-template-columns: 12ch 4px auto;
  margin-top: 0rem;
  min-width: 125ch;
  max-width: fit-content;
  font-family: "Courier New", Courier, monospace;
  font-size: inherit;
  letter-spacing: inherit;
  position: relative;
  top: 12px;
`;

export const SectionMessageContainer = styled.div`
  width: 125ch;
  display: grid;
  grid-column: 1 / -1;
  padding: 0.5rem 0;
  border-radius: 8px;
  align-content: center;
  justify-content: center;
  background-color: rgb(236, 236, 236);
`;

export const TransitionEditorContainer = styled.div`
  grid-column: 1 /-1;
  grid-row: 1;
  margin-left: 13ch;
  justify-self: center;
  max-width: calc(50ch - 2px);
  margin-bottom: 4px;
  border-radius: 1rem;
  padding: .125rem 0rem;
`


export const SectionContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isDragging'].includes(prop),
})`
  background: ${(props) => props.isDragging ? "rgba(255,255,255,0.75)"  : "transparent"};
  box-shadow: ${(props) => props.isDragging ? "2px 2px 3px rgba(0,0,0,0.25)"  : "none"};
  border-radius: 8px;
`;

export const IndividualSectionList = styled.div.withConfig({
  shouldForwardProp: (prop) => !['rowIndex', 'isDraggingOver'].includes(prop),
})`
  width: 125ch;
  border-radius: 8px;
  background-color: ${(props) =>
    props.isDraggingOver ? "rgba(209, 234, 255, .65)" : "rgba(0,0,0,0)"};
  transition: background-color 1s ease-in-out;
`;
