import React from "react";
import { useSelector } from "react-redux";
import { getSectionOrder } from "../Common/Utils/ElementsAndSectionUtils";
import { PresentationIndividualSection } from "./PresentationIndividualSection";

function PresentationSections(
  {
    setSectionExpanded,
    sectionExpanded,
    sections,
    elements,
    dialogue,
    page,
    selectedElement,
  },
) {
  const handleExpandSection = (sectionName) => {
    sectionExpanded.includes(sectionName)
      ? setSectionExpanded(
        sectionExpanded.filter((x) => x !== sectionName),
      )
      : setSectionExpanded([
        ...sectionExpanded,
        sectionName,
      ]);
  };
  // isSecitonEx
  // Each component handles their own state of expanded or not
  // this can be modified by the outside by passing a boolean that is checked only once?
  // maybe a reference

  const metadata = useSelector((state) => state.document.metadata);
  const sectionOrderArray = metadata.sectionOrderArray === undefined
    ? {}
    : metadata.sectionOrderArray;

  const sectionOrder = getSectionOrder(sectionOrderArray, page, {
    sections: sections,
    elements: elements,
  });
  const sectionDisplay = [
    ...sectionOrder,
    ...sections.map((s) => s.sectionName).filter((s) =>
      !sectionOrder.includes(s)
    ),
  ];
  return (
    <>
      {/* For each section render a section name and sectior row that can be expanded */}
      {sectionDisplay.map((s) => {
        const section = sections.find((sec) => sec.sectionName === s);
        if (section === undefined) {
          return null;
        } else {
          return (
            <PresentationIndividualSection
              key={section.sectionName}
              sectionExpanded={sectionExpanded}
              page={page}
              dialogue={dialogue}
              section={section}
              handleExpandSection={handleExpandSection}
              selectedElement={selectedElement}
            />
          );
        }
      })}
    </>
  );
}

export default PresentationSections;
