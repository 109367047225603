import React from "react";
import StaffName from "../Staff/StaffName";
import RowSpacer from "../Common/RowSpacer";
import PresentationDialogue from "./PresentationDialogue";
import { StaffContainer } from "../Common/Styles/StaffStyles";
export default function SectionElementExpanded({
  element,
  highlighted,
  rowStyle,
  dialogue,
  section,
  page,
}) {

  const sectionElementTransitions =
    section.elements.find((elem) => elem.elementName === element)["transitions"];
  
  const [showStageDirections, setShowStageDirections] = React.useState(false);
  const [stageDirections, setStageDirections] = React.useState(
    sectionElementTransitions === undefined ? [] : sectionElementTransitions
  );

  return (
    <StaffContainer>
      <StaffName
        elementName={element}
        menuOptions={[]}
        highlighted={highlighted}
        isSLine={false}
      />
      <PresentationDialogue
        stageDirections={stageDirections}
        setStageDirections={setStageDirections}
        setShowStageDirections={setShowStageDirections}
        showStageDirections={showStageDirections}
        highlighted={highlighted}
        rowStyle={rowStyle}
        element={element}
        dialogue={dialogue}
        sectionName={section.sectionName}
        page={page}
      />
    </StaffContainer>
  );
}
