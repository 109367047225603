import React, { useRef, useState } from "react";
import SwipingArrows from "../Common/SwipingArrows";

export function PresentationTouchSwipeContainer(
  { children, StaffElementsRef, page, setPage },
) {
  const touchStart = useRef(null);
  const touchEnd = useRef(null);
  const [touchMoveDistance, setTouchMoveDistance] = useState(0);

  const minSwipeDistance = 150;
  // Set a max distance that the staff container div can travel
  const onTouchStart = (e) => {
    setTouchMoveDistance(0);
    // touchMove.current = null;
    touchEnd.current = null;
    touchStart.current = e.targetTouches[0].clientX;
  };

  const onTouchMove = (e) => {
    const distance = e.touches[0].clientX - touchStart.current;
    if (Math.abs(distance) < minSwipeDistance) {
      setTouchMoveDistance(0);
      touchEnd.current = e.targetTouches[0].clientX;
      // dont allow back swipe when on the first page
    } else if (!(distance > 0 && page === 1)) {
      setTouchMoveDistance(distance);
      touchEnd.current = e.targetTouches[0].clientX;
    }
  };

  const onTouchEnd = () => {
    if (!touchStart.current || !touchEnd.current) return;
    const distance = touchStart.current - touchEnd.current;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe) {
      setPage(page + 1);
      setTouchMoveDistance(0);
    } else if (isRightSwipe && page > 1) {
      setPage(page - 1);
      setTouchMoveDistance(0);
    }
    setTouchMoveDistance(0);
  };

  return (
    <>
      <SwipingArrows touchMoveDistance={touchMoveDistance} />
      <div
        ref={StaffElementsRef}
        style={{
          touchAction: "pan-y",
          transition: "transform .13s ease-out",
          transform: `translateX(${touchMoveDistance === 0 ? "0" : touchMoveDistance > 0 ? "3" : "-3"
            }%)`,
        }}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
      >
        {children}
      </div>
    </>
  );
}
