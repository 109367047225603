import React from "react";
import { Typography } from "@mui/joy";
import {
  getElementOrder,
  getElementsInSection,
} from "../Common/Utils/ElementsAndSectionUtils";
import SectionElementExpanded from "./SectionElementExpanded";
import SectionHeaderButton from "../Common/SectionHeaderButton";
import { StaffContainerHeader, SectionMessageContainer } from "../Common/Styles/StaffStyles";

export function PresentationIndividualSection(
  {
    section,
    page,
    dialogue,
    selectedElement,
  },
) {
  const [isSectionExpanded, setIsSectionExpanded] = React.useState(false);
  const elementsInSection = getElementsInSection(page, section);
  React.useEffect(() => {
    if (elementsInSection.includes(selectedElement)) {
      setIsSectionExpanded(true);
    }
  }, [selectedElement]);
  const elementOrderArray = section.elementOrderArray === undefined
    ? {}
    : section.elementOrderArray;
  const elementOrder = getElementOrder(
    elementOrderArray,
    elementsInSection,
    page,
  );
  const elementDisplay = [
    ...elementOrder.filter((e) => elementsInSection.includes(e)),
    ...elementsInSection.filter((e) => !elementOrder.includes(e)),
  ];
  const sLine = section.elements[0] !== undefined ? section.elements[0] : null;
  const sLineHighlighted = sLine === null
    ? false
    : (sLine === selectedElement)
      ? true
      : false;
  return (
    <div
      key={section.sectionName}
    >
      {selectedElement === null ||
        elementsInSection.includes(selectedElement)
        ? (
          <div>
            <StaffContainerHeader>
              <SectionHeaderButton
                sectionName={section.sectionName}
                onClickAction={() => setIsSectionExpanded(!isSectionExpanded)}
                isSectionExpanded={isSectionExpanded}
              />
            </StaffContainerHeader>

            {elementsInSection.length > 0
              ? (
                elementDisplay.map((element, index) => {
                  if (index === 0 || isSectionExpanded) {
                    return (
                      <SectionElementExpanded
                        key={element}
                        element={element}
                        highlighted={selectedElement === element}
                        rowStyle={"staff-row-presentation"}
                        dialogue={dialogue}
                        section={section}
                        page={page}
                        emptySpacer={index ===
                          elementsInSection.length - 1}
                      />
                    );
                  }
                })
              )
              : (
                <SectionMessageContainer>
                  <Typography level="h4">
                    Section contains no elements
                  </Typography>
                </SectionMessageContainer>
              )}
            {/* if sectionExpeded === true and selection has more than one element:  add a spacer */}
          </div>
        )
        : (null)}
    </div>
  );
}
