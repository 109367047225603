import React, { useState } from "react";
import Box from "@mui/material/Box";
import {
  Avatar,
  AvatarGroup,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material";

const buttonTheme = createTheme({
  palette: {
    ochre: {
      main: "#c8c8c8",
      light: "#f8f8f8",
      dark: "#979798",
      contrastText: "#000",
    },
  },
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          backgroundColor: "#fff",
          padding: "0 1rem 0px 1rem",
          borderRadius: "14px",
          border: "1px solid grey",
          fontFamily: "Google Sans, sans-serif",
          letterSpacing: "1px",
          fontSize: "1.2rem",
          boxShadow: "none",
          textTransform: "none",
          margin: "0 0 0 0",
          color: "#000",
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          padding: "0",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          whiteSpace: "nowrap",
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          paddingLeft: "24px",
          paddingRight: "48px",
        },
      },
    },
  },
});
const createdAtFormat = (isoTimestamp) => {
  const timestamp = new Date(isoTimestamp);
  return timestamp.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};

function updatedAtFormat(isoTimestamp) {
  const now = new Date();
  const timestamp = new Date(isoTimestamp);

  // Calculate the difference in milliseconds
  const differenceInMs = now - timestamp;
  const differenceInHours = differenceInMs / (1000 * 60 * 60);

  if (differenceInHours < 24 && differenceInHours > 0) {
    // If the difference is less than 24 hours, return "X hours ago"
    const hoursAgo = Math.floor(differenceInHours);
    if (hoursAgo > 0) {
      return `${hoursAgo} hour${hoursAgo !== 1 ? "s" : ""} ago`;
      // If the difference is less than 1 hours, return "X minutes ago"
    } else {
      const minutesAgo = Math.floor(differenceInMs / (1000 * 60));
      if (minutesAgo === 0) {
        return "Just now";
      } else {
        return `${minutesAgo} minute${minutesAgo !== 1 ? "s" : ""} ago`;
      }
    }
  } else {
    // Otherwise, return a readable date string
    return timestamp.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  }
}

export function FileSelectorTable(
  { files, handleClose, handleLoadFile, tabValue },
) {
  const [fileSelection, setFileSelection] = useState("");
  return (
    <>
      <ThemeProvider theme={buttonTheme}>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ minHeight: "140px", maxHeight: "440px"  }}>
            <Table
              stickyHeader={true}
            >
              <TableHead>
                <TableRow sx={{ padding: "0" }}>
                  <TableCell>Filename</TableCell>
                  <TableCell>
                    Last Updated
                  </TableCell>
                  {tabValue === "1" &&
                    (
                      <TableCell>
                        Created on
                      </TableCell>
                    )}
                  {tabValue === "1" &&
                    <TableCell>Shared with</TableCell>}
                  {tabValue === "2" &&
                    <TableCell>Owner</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {files.map((file) => {
                  const createdDate = createdAtFormat(file.created_at);
                  const updatedDate = updatedAtFormat(
                    file.updated_at,
                  );
                  return (
                    <TableRow
                      key={file.id}
                      value={file.id}
                      onClick={() => setFileSelection(file.id)}
                      selected={fileSelection === file.id}
                      style={fileSelection === file.id
                        ? {
                          background: "#d1eaff",
                        }
                        : {}}
                    >
                      <TableCell>
                        {file.title}
                      </TableCell>
                      <TableCell>
                        {updatedDate}
                      </TableCell>
                      {tabValue === "1" &&
                        (
                          <TableCell>
                            {createdDate}
                          </TableCell>
                        )}
                      {tabValue === "1" &&
                        (
                          <TableCell
                            padding={"none"}
                          >
                            <AvatarGroup
                              total={file.viewer.length}
                              max={2}
                              sx={{ width: "fit-content" }}
                            >
                              {file.viewer.slice(0, 3).map((f) => (
                                <Avatar
                                  key={f.user_id}
                                  alt={f.username}
                                  src={f?.profilePic}
                                >
                                  {f.username.toUpperCase()[0]}
                                </Avatar>
                              ))}
                            </AvatarGroup>
                          </TableCell>
                        )}
                      {tabValue === "2" &&
                        <TableCell>{file.owner}</TableCell>}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "1rem",
          }}
        >
          <Button
            disabled={fileSelection === ""}
            onClick={() => {
              handleLoadFile(fileSelection);
              setFileSelection("");
              handleClose();
            }}
            type="submit"
          >
            Open
          </Button>
        </Box>
      </ThemeProvider>
    </>
  );
}
