import React, { useEffect, useRef, useState } from "react";
import PresentationElements from "./PresentationElements.jsx";
import PresentationSections from "./PresentationSections.jsx";
import { getElementsInSection } from "../Common/Utils/ElementsAndSectionUtils.js";
import { Typography } from "@mui/joy";
import {
  EmptyStaffMessageContainer,
  MeasureNumberContainer,
  StaffRuler,
} from "../Common/Styles/StaffStyles.jsx";
import { PresentationTouchSwipeContainer } from "./PresentationTouchSwipeContainer.jsx";

function PresentationStaff(
  {
    elements,
    sections,
    dialogue,
    page,
    setPage,
    setDialogue,
    characterFocus,
    setCharacterFocus,
    handleShowSectionModal,
    selectedElement,
    documentId,
    setSectionExpanded,
    sectionExpanded,
  },
) {
  const StaffElementsRef = useRef(null);
  const [staffHeight, setStaffHeight] = useState("100vh");
  useEffect(() => {
    if (StaffElementsRef.current !== null) {
      setStaffHeight(`calc(${StaffElementsRef.current.clientHeight}px + 5rem)`);
    }
  }, [StaffElementsRef.current, sectionExpanded]);

  useEffect(() => {
    const allSections = sections.map((sec) => sec.sectionName);
    setSectionExpanded(allSections);
  }, [documentId]);

  const sectionElementsToExclude = sections.map((section) => {
    return getElementsInSection(page, section);
  }).flat();
  return (
    <div className="editor-container">
      {(elements.length === 0 && sections.length === 0)
        ? (
          <EmptyStaffMessageContainer>
            <Typography
              level="h2"
              sx={{ letterSpacing: "1px", color: "rgba(0,0,0,0.6)" }}
            >
              Open a file
            </Typography>
          </EmptyStaffMessageContainer>
        )
        : (
          <>
            <StaffRuler staffHeight={staffHeight} />
            <div className="canvas-container">
              <MeasureNumberContainer>
                {page}
              </MeasureNumberContainer>
              <PresentationTouchSwipeContainer
                StaffElementsRef={StaffElementsRef}
                setPage={setPage}
                page={page}
              >
                <PresentationSections
                  setSectionExpanded={setSectionExpanded}
                  sectionExpanded={sectionExpanded}
                  sections={sections}
                  elements={elements}
                  dialogue={dialogue}
                  setDialogue={setDialogue}
                  characterFocus={characterFocus}
                  setCharacterFocus={setCharacterFocus}
                  page={page}
                  selectedElement={selectedElement}
                  // Empty Measure Show Section Modals
                  handleShowSectionModal={handleShowSectionModal}
                />
                <PresentationElements
                  sectionElementsToExclude={sectionElementsToExclude}
                  elements={elements}
                  dialogue={dialogue}
                  page={page}
                  setDialogue={setDialogue}
                  characterFocus={characterFocus}
                  setCharacterFocus={setCharacterFocus}
                  selectedElement={selectedElement}
                />
              </PresentationTouchSwipeContainer>
            </div>
          </>
        )}
    </div>
  );
}

export default PresentationStaff;
