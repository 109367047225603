import React, { useEffect, useRef, useState } from "react";
import { getElementsInSection } from "../Common/Utils/ElementsAndSectionUtils.js";
import Elements from "./Elements";
import Sections from "./Sections.jsx";
import { Typography } from "@mui/joy";
import { EmptyStaffMessageContainer, MeasureNumberContainer, StaffRuler } from "../Common/Styles/StaffStyles.jsx";

function StaffElements(
  {
    elements,
    sections,
    dialogue,
    page,
    setDialogue,
    characterFocus,
    setCharacterFocus,
    handleShowSectionModal,
    documentId,
    staffElements,
    setStaffElements,
  },
) {
  const [sectionExpanded, setSectionExpanded] = useState([]);
  const staffElementsRef = useRef(null);
  const [staffHeight, setStaffHeight] = useState("100vh");
  function measureRangeCheck(mR, page) {
    return ((mR[0] === 1 && mR[1] === -1) || (mR[0] <= page && mR[1] >= page) ||
      (mR[0] <= page && mR[1] === -1));
  }
  useEffect(() => {
    if (staffElementsRef.current !== null) {
      setStaffHeight(`calc(${staffElementsRef.current.clientHeight}px + 5rem)`);
    }
  }, [staffElementsRef.current, sectionExpanded]);

  useEffect(() => {
    setSectionExpanded([]);
  }, [documentId]);
  
  const sectionElementsToExclude = sections.map((section) => {
    return getElementsInSection(page, section);
  }).flat();

  return (
    <div className="editor-container">
      <StaffRuler staffHeight={staffHeight} />
      <div className="canvas-container">
        {(elements.length === 0 && sections.length === 0) && (
          <EmptyStaffMessageContainer>
            <Typography
              level="h2"
              sx={{ letterSpacing: "1px", color: "rgba(0,0,0,0.6)" }}
            >
              Add elements or Open file
            </Typography>
          </EmptyStaffMessageContainer>
        )}
        <MeasureNumberContainer>
            {page}
        </MeasureNumberContainer>
        <div
          ref={staffElementsRef}
        >
          <Sections
            staffElements={staffElements}
            setStaffElements={setStaffElements}
            setSectionExpanded={setSectionExpanded}
            sectionExpanded={sectionExpanded}
            sections={sections}
            dialogue={dialogue}
            setDialogue={setDialogue}
            characterFocus={characterFocus}
            setCharacterFocus={setCharacterFocus}
            page={page}
            // Empty Measure Show Section Modals
            handleShowSectionModal={handleShowSectionModal}
          />
          <Elements
            sectionElementsToExclude={sectionElementsToExclude}
            elements={elements}
            dialogue={dialogue}
            page={page}
            setDialogue={setDialogue}
            characterFocus={characterFocus}
            setCharacterFocus={setCharacterFocus}
          />
        </div>
      </div>
    </div>
  );
}

export default StaffElements;
