import React from "react";
import styled, { keyframes } from "styled-components";

const arrowAnimation = keyframes`
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-10px, -10px);
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: rotate(45deg) translate(10px, 10px);
  }
`;

const Arrow = styled.div.withConfig({
  shouldForwardProp: (prop) => !["touchMoveDistance"].includes(prop),
})`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) ${(props) =>
    props.touchMoveDistance > 0 ? "rotate(90deg)" : "rotate(-90deg)"};
  opacity: 1;

  span {
    display: block;
    width: 5.5vw;
    height: 5.5vw;
    border-bottom: .75rem solid grey;
    border-right: .75rem solid grey;
    border-radius: 8px;
    transform: rotate(45deg);
    animation: ${arrowAnimation} 2s infinite;

  span:nth-child(2) {
    animation-delay: -0.2s;
  }
`;
export default function SwipingArrows({ touchMoveDistance }) {
  return (
    <>
      <div
        style={{
          display: touchMoveDistance === 0 ? "none" : "block",
          zIndex: "2",
          postition: "fixed",
        }}
      >
        <Arrow touchMoveDistance={touchMoveDistance}>
          <span></span>
          <span></span>
          <span></span>
        </Arrow>
      </div>
    </>
  );
}
