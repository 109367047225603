import React from "react";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { Box, Button, Typography } from "@mui/joy";
import DragIndicator from "@mui/icons-material/DragIndicator";

export default function SectionHeaderButton(
  { sectionName, onClickAction, isSectionExpanded, dragHandleProps },
) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        gridColumn: "3 /-1",
      }}
    >
      <Button
        variant="plain"
        onClick={onClickAction}
        level="h4"
        endDecorator={
          <ExpandMoreRoundedIcon
            sx={{
              color: "#000",
              transform: `rotate(${isSectionExpanded ? 180 : 0}deg)`,
              transition: "transform 0.1s ease-in-out",
            }}
          />
        }
      >
        <Typography
          level="h4"
          sx={{
            pl: "1rem",
            fontFamily: "serif",
            fontStyle: "italic",
            fontWeight: 700,
          }}
        >
          {sectionName}
        </Typography>
      </Button>
      {dragHandleProps &&
        (
          <div {...dragHandleProps} style={{ alignSelf: "center" }}>
            <DragIndicator />
          </div>
        )}
    </Box>
  );
}
